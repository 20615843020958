import {FiscalType} from '@prisma/client';
import {FlatLoan, getRealLoansTreeFromFlatLoans} from '~/domains/property';
import {getRealLoanDuration} from '../../legacy/utils/utilsPrets';
import {monthDateDiff} from '../../services/dates';

type TargetSIPCase = 'CASE_AUTHENTIC_ACT' | 'CASE_AUTHENTIC_ACT_RENT' | 'CASE_DELIVERY' | 'CASE_RENT_LOAN';

export const minDate = (dates: (Date | null | undefined)[]) => {
  const filteredDates = dates.filter(d => d !== null && d !== undefined && typeof d.getTime === 'function') as Date[];
  const filteredNumberedDates = filteredDates.map(d => {
    return d.getTime();
  });
  return new Date(Math.min(...filteredNumberedDates));
};

// CC_caracteristiques_investissement.firstSIPYear
export const getFirstSIPYearWithoutPrets = ({
  authenticActDate = new Date(500, 0, 1, 12),
  deliveryDate = new Date(500, 0, 1, 12),
  firstRentDate = new Date(500, 0, 1, 12),
  firstLoanStartDate,
  targetSIPCase,
}: {
  authenticActDate?: Date;
  deliveryDate?: Date;
  firstRentDate?: Date;
  firstLoanStartDate?: Date;
  targetSIPCase: TargetSIPCase;
}) => {
  let returnDate: Date;
  switch (targetSIPCase) {
    case 'CASE_DELIVERY':
      returnDate = deliveryDate;
      break;
    case 'CASE_AUTHENTIC_ACT':
    case 'CASE_AUTHENTIC_ACT_RENT':
      returnDate = authenticActDate;
      break;
    default:
      const firstSIPYear = firstLoanStartDate
        ? minDate([firstLoanStartDate || new Date(500, 0, 1, 12), firstRentDate || new Date(500, 0, 1, 12)])
        : firstRentDate || new Date(500, 0, 1, 12);
      returnDate = firstSIPYear;
      break;
  }
  // should make the difference between RG with and without travaux

  return returnDate.getFullYear();
};

interface GetFirstSIPYearProps {
  authenticActDate?: Date;
  deliveryDate?: Date;
  firstRentDate?: Date;
  prets: {loanStartDate?: Date}[];
  fiscalType: FiscalType;
}
// CC_caracteristiques_investissement.firstSIPYear
export const getFirstSIPYear = ({
  authenticActDate = new Date(500, 0, 1, 12),
  deliveryDate = new Date(500, 0, 1, 12),
  firstRentDate = new Date(500, 0, 1, 12),
  prets,
  fiscalType,
}: GetFirstSIPYearProps) => {
  const targetSIPCase = getTargetSIPCase(fiscalType);
  const firstLoanStartDate = getFirstLoanStartDate(prets);
  return getFirstSIPYearWithoutPrets({
    targetSIPCase,
    authenticActDate,
    deliveryDate,
    firstLoanStartDate: firstLoanStartDate || undefined,
    firstRentDate,
  });
};

/**
 * Si tous les lots ont une première année < 2000,
 * ça signifie qu'ils sont tous en années relatives. On retroune le minimum.
 * Sinon, certains sont en années absolues (les lots "démarrés"),
 * les autres valent 0 (pas démarrés). On retourne alors la pus petite des années aboslues (> 2000)
 * @param propertiesProps GetFirstSIPYearProps[]
 * @returns
 */
export const getInvestmentFirstSIPYear = (propertiesProps: GetFirstSIPYearProps[]): number => {
  // ne devrait pas arriver, une simu a toujours au moins un lot
  if (propertiesProps.length === 0) {
    return 0;
  }
  if (propertiesProps.every(prop => getFirstSIPYear(prop) < 1000)) {
    return propertiesProps.map(prop => getFirstSIPYear(prop)).reduce((pA1, pA2) => Math.min(pA1, pA2), 3000);
  } else {
    return propertiesProps
      .filter(prop => getFirstSIPYear(prop) > 1000)
      .map(prop => getFirstSIPYear(prop))
      .reduce((pA1, pA2) => Math.min(pA1, pA2), 3000);
  }
};

export const getNumberOfMOnthsBetweenYearAndDate = ({
  firstSIPYear,
  targetStartDate,
}: {
  firstSIPYear: number;
  targetStartDate: Date;
}) => {
  const numberOfMonths = 12 * (targetStartDate.getFullYear() - firstSIPYear) + targetStartDate.getMonth();
  return numberOfMonths > 180 || numberOfMonths < 0 ? 0 : numberOfMonths;
};

export const getMonthShiftFromFirstSIPYear = ({
  targetSIPCase,
  authenticActDate,
  deliveryDate,
  firstLoanStartDate,
  firstRentDate,
}: {
  targetSIPCase: TargetSIPCase;
  firstRentDate: Date;
  firstLoanStartDate: Date;
  deliveryDate: Date;
  authenticActDate: Date;
}) => {
  let targetStartDate: Date;
  const firstSIPYear = getFirstSIPYearWithoutPrets({
    targetSIPCase,
    authenticActDate,
    deliveryDate,
    firstLoanStartDate: firstLoanStartDate || undefined,
    firstRentDate,
  });
  switch (targetSIPCase) {
    case 'CASE_DELIVERY':
      targetStartDate = deliveryDate;
      break;
    case 'CASE_AUTHENTIC_ACT':
      targetStartDate = authenticActDate;
      break;
    case 'CASE_AUTHENTIC_ACT_RENT':
    default:
      targetStartDate = firstRentDate;
      break;
  }
  // should make the difference between RG with and without travaux
  return getNumberOfMOnthsBetweenYearAndDate({targetStartDate, firstSIPYear});
};

const deliveryCaseFiscalTypes: FiscalType[] = ['DEMEMBREMENT'];
const authenticActRentCaseFiscalTypes: FiscalType[] = ['MALRAUX', 'PINEL_DENORMANDIE'];
const authenticActCaseFiscalTypes: FiscalType[] = ['REFORME_LMNP_RG', 'REGIME_GENERAL', 'DEFICIT_FONCIER', 'MH'];
export const getTargetSIPCase = (fiscalType: FiscalType): TargetSIPCase => {
  if (deliveryCaseFiscalTypes.includes(fiscalType)) {
    return 'CASE_DELIVERY';
  }
  if (authenticActCaseFiscalTypes.includes(fiscalType)) {
    return 'CASE_AUTHENTIC_ACT';
  }
  if (authenticActRentCaseFiscalTypes.includes(fiscalType)) {
    return 'CASE_AUTHENTIC_ACT_RENT';
  }
  return 'CASE_RENT_LOAN';
};

export const getFirstLoanStartDate = (prets: {loanStartDate?: Date}[]) => {
  const firstLoanStartDate =
    prets && prets.length > 0
      ? prets[0].loanStartDate
        ? new Date(prets[0].loanStartDate)
        : new Date(500, 0, 1, 12)
      : null;
  return firstLoanStartDate;
};

export const getFixTargetStartDate = ({
  deliveryDate = new Date(500, 0, 1, 12),
  firstRentDate = new Date(500, 0, 1, 12),
  fiscalType,
}: {
  deliveryDate?: Date;
  firstRentDate?: Date;
  fiscalType: FiscalType;
}) => {
  if (deliveryCaseFiscalTypes.includes(fiscalType)) {
    return deliveryDate;
  }
  return firstRentDate;
};

export interface MinimalLoanForDurationComputing {
  loanDuration: number;
  id: string;
  loanStartDate?: Date;
  renegos?: MinimalLoanForDurationComputing[];
  isReimbursmentTotal: boolean;
  anticipatedEndDate?: Date;
}

export const getFullLoansDuration = (loans: MinimalLoanForDurationComputing[], firstLoanStartDate?: Date): number => {
  const _firstLoanStartDate = firstLoanStartDate ? firstLoanStartDate : getFirstLoanStartDate(loans);
  let monthDuration = 0;
  for (let i = 0; i < loans.length; i += 1) {
    const loan = {
      ...loans[i],
    };
    const {loanDuration, loanStartDate, renegos, anticipatedEndDate, isReimbursmentTotal} = loan;
    const monthDiffWithFirstLoan =
      loanStartDate && _firstLoanStartDate ? monthDateDiff(_firstLoanStartDate, loanStartDate) : 0;

    const realLoanDuration = getRealLoanDuration({
      loanStartDate,
      renegoStartDate:
        renegos && renegos.length > 0 ? renegos[0].loanStartDate : isReimbursmentTotal ? anticipatedEndDate : undefined,
      loanDuration,
    });

    monthDuration = Math.max(
      monthDuration,
      monthDiffWithFirstLoan + realLoanDuration + (renegos === undefined ? 0 : getFullLoansDuration(renegos)),
    );
  }

  return monthDuration;
};
export const getEndOfLoanYearIndex = (loans: FlatLoan[], firstSIPYear: number): number => {
  const realTreeLoan = getRealLoansTreeFromFlatLoans(loans);
  const firstLoanStartDate = getFirstLoanStartDate(loans);

  // ex: if firstSIPYear = 2023 and firstLoanStartDate = 01/01/500 (because undefined in Financement)
  // validFirstLoanStartDate should be 01/01/2023 for next calculation
  const validFirstLoanStartDate =
    firstLoanStartDate && firstSIPYear > 1000 && firstLoanStartDate.getFullYear() < 1000
      ? new Date(firstSIPYear + firstLoanStartDate.getFullYear() - 500, firstLoanStartDate.getMonth(), 1, 12)
      : firstLoanStartDate
        ? new Date(firstLoanStartDate)
        : null;

  const monthShiftFirstLoan = validFirstLoanStartDate
    ? validFirstLoanStartDate.getMonth() + 12 * (validFirstLoanStartDate.getFullYear() - firstSIPYear)
    : 0;

  const loansDuration = getFullLoansDuration(realTreeLoan);

  const dureMoisTotale = monthShiftFirstLoan + loansDuration;

  return Math.floor(dureMoisTotale / 12) + 1;
};

export const getFirstRelativeYearOfRentFrom1 = ({
  prets,
  fiscalType,
  authenticActDate,
  deliveryDate,
  firstRentDate,
}: {
  authenticActDate?: Date;
  deliveryDate?: Date;
  firstRentDate?: Date;
  prets: {loanStartDate?: Date}[];
  fiscalType: FiscalType;
}) => {
  const firstSIPYear = getFirstSIPYear({
    fiscalType,
    authenticActDate,
    deliveryDate,
    firstRentDate,
    prets,
  });
  return firstRentDate ? getFirstRelativeRentYearFrom1({firstRentDate, firstSIPYear}) : 1;
};

export const getFirstRelativeRentYearFrom1 = ({
  firstRentDate,
  firstSIPYear,
}: {
  firstSIPYear: number;
  firstRentDate: Date;
}) => {
  return getFirstRelativeRentYear({firstRentDate, firstSIPYear}) + 1;
};
export const getFirstRelativeRentYear = ({
  firstRentDate,
  firstSIPYear,
}: {
  firstSIPYear: number;
  firstRentDate: Date | string;
}) => {
  return Math.abs(new Date(firstRentDate).getFullYear() - firstSIPYear) > 1000
    ? 0
    : new Date(firstRentDate).getFullYear() - firstSIPYear;
};
